import React, { useMemo } from "react";
import { AuthProvider, FirebaseAppProvider } from "reactfire";
import {
  getAuth,
  onAuthStateChanged,
  getIdToken,
  signOut,
} from "firebase/auth";
import themeconfig from "../Config/themeconfig";

const firebaseConfig = {
  apiKey: "AIzaSyBqbyZ1P5KmYDXO3USlXov4CY8noHZTals",
  authDomain: "xtour-auth.firebaseapp.com",
  projectId: "xtour-auth",
  storageBucket: "xtour-auth.appspot.com",
  messagingSenderId: "267365127204",
  appId: "1:267365127204:web:63fbd7a4ffa27f5634f4b1",
  measurementId: "G-TEGB3G3SM2",
};

export const checkStorageItems = () => {
  return (
    localStorage.getItem("active-user-id") &&
    localStorage.getItem("user_role") &&
    localStorage.getItem("parent_id")
  );
};

export const getUserToken = async () => {
  return new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        const token = await getIdToken(user);
        resolve(token);
      } else {
        resolve(null);
        console.log("User not logged in");
        localStorage.removeItem("active-user-id");
        localStorage.removeItem("user_role");
        localStorage.removeItem("parent_id");
      }
      unsub();
    });
  });
};

const FirebaseProviderSDKs = ({ children }) => {
  const auth = useMemo(() => getAuth(), []);

  return <>{auth && <AuthProvider sdk={auth}>{children}</AuthProvider>}</>;
};

const FirebaseProvider = ({ children }) => {
  return (
    <>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <FirebaseProviderSDKs>{children}</FirebaseProviderSDKs>
      </FirebaseAppProvider>
    </>
  );
};

export default FirebaseProvider;
